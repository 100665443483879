import "./SmallBtn.css";
import React from "react";

export default class SmallBtn extends React.Component<
  {
    onClick: any;
    disabled?: boolean;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <button
        className="small-btn"
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </button>
    );
  }
}
