import React from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import "./MembershipApplication.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import { MainContext } from "../../contexts/MainContext";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import Input from "../../components/Input";
import UnderlinedTitle from "../../components/UnderlinedTitle";
import TextCheckbox from "../../components/TextCheckbox";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import ErrorMessage from "../../components/ErrorMessage";
import {
  dateToStr,
  inputStrToDate,
  reformatDateReverse,
} from "../../functions/utils";
import qs from "qs";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
  }>;

export default class MembershipApplication extends React.Component<
  ComposedProps,
  {
    clubId: string;
    gender: string;
    fName: string;
    lName: string;
    birthDate: string;
    joinDate: string;
    street: string;
    houseNumber: string;
    zipcode: string;
    city: string;
    phoneNumber: string;
    mobileNumber: string;
    email: string;
    departmentArr: Array<any>;
    informationPaper: boolean;
    informationElectric: boolean;
    noInformation: boolean;
    other: string;
    adults: string;
    children: string;
    iban: string;
    creditInstitution: string;
    bic: string;
    accountOwner: string;
    privacyAccepted: boolean;
    requestLoading: boolean;
    userLoaded: boolean;
    requestErrMsg: string | null;
    highlight: boolean;
    redirect: string | null;
    membershipAppSetLoaded: boolean;
    registrationReq: boolean;
    nextRedirect: string | null;
    isStudent: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);

    this.onGenderChange = this.onGenderChange.bind(this);
    this.state = {
      clubId: this.props.match.params.clubId,
      gender: "",
      fName: "",
      lName: "",
      birthDate: "",
      joinDate: "",
      street: "",
      houseNumber: "",
      zipcode: "",
      city: "",
      phoneNumber: "",
      mobileNumber: "",
      email: "",
      departmentArr: [],
      informationPaper: false,
      informationElectric: false,
      noInformation: false,
      other: "",
      adults: "",
      children: "",
      iban: "",
      creditInstitution: "",
      bic: "",
      accountOwner: "",
      privacyAccepted: false,
      requestLoading: false,
      userLoaded: false,
      requestErrMsg: null,
      highlight: false,
      redirect: null,
      membershipAppSetLoaded: false,
      registrationReq:
        qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).regReq === "true",
      nextRedirect:
        (qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).redirect || "") + "",
      isStudent: false,
    };
  }

  componentDidMount = () => {
    this.requestMemAppSet();
    this.requestOwnUser();
  };

  requestMemAppSet = async () => {
    await fetchRetry(
      "getMemAppSet",
      {
        clubId: this.state.clubId,
      },
      1,
      5
    )
      .then(this.handleMemAppSet)
      .catch(this.handleError);
  };

  requestOwnUser = async () => {
    await fetchRetry(
      "getUserFromId",
      { targetUserId: this.context.selectedUserId },
      1,
      5
    )
      .then(this.handleUserData)
      .catch(this.handleError);
  };

  handleUserData = ({ data }: any) => {
    this.setState({ userLoaded: true });
    if (data.success) {
      const userData = data.data;
      this.setState({
        fName: userData?.fName || "",
        lName: userData?.lName || "",
        birthDate: userData?.birthDate
          ? reformatDateReverse(userData.birthDate)
          : "",
        street: userData?.address?.street || "",
        houseNumber: userData?.address?.houseNumber || "",
        zipcode: userData?.address?.zipcode || "",
        city: userData?.address?.city || "",
        phoneNumber: userData?.phoneNumber || "",
        mobileNumber: userData?.mobileNumber || "",
        email: userData?.email || "",
      });
    }
  };

  handleMemAppSet = ({ data }: any) => {
    if (data.success) {
      const memAppSetData = data.data;
      const departmentArr = memAppSetData.departmentArr
        .map((department: any) => {
          return { ...department, value: false };
        })
        .sort((a: any, b: any) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: "base",
          })
        );
      this.setState({ departmentArr, membershipAppSetLoaded: true });
    }
  };

  onGenderChange(e: any) {
    this.setState({ gender: e.target.value });
  }

  handleError = (err: any) => {
    console.error(err);
  };

  handleFNameChange = (val: any) => {
    this.setState({ fName: val });
  };

  handleLNameChange = (val: any) => {
    this.setState({ lName: val });
  };

  handlePhoneNumberChange = (val: any) => {
    this.setState({ phoneNumber: val });
  };

  handleMobileNumberChange = (val: any) => {
    this.setState({ mobileNumber: val });
  };

  handleEmailChange = (val: any) => {
    this.setState({ email: val });
  };

  handleOtherChange = (val: any) => {
    this.setState({ other: val });
  };

  handleCityChange = (val: any) => {
    this.setState({ city: val });
  };

  handleHouseNumberChange = (val: any) => {
    this.setState({ houseNumber: val });
  };

  handleZipcodeChange = (val: any) => {
    this.setState({ zipcode: val });
  };

  handleStreetChange = (val: any) => {
    this.setState({ street: val });
  };

  handleBirthDateChange = (val: any) => {
    this.setState({ birthDate: val.target.value });
  };

  handleJoinDateChange = (val: any) => {
    this.setState({ joinDate: val.target.value });
  };

  handleChildrenChange = (val: any) => {
    this.setState({ children: val });
  };

  handleAdultsChange = (val: any) => {
    this.setState({ adults: val });
  };

  handleIbanChange = (val: any) => {
    this.setState({ iban: val });
  };

  handleCreditInstitutionChange = (val: any) => {
    this.setState({ creditInstitution: val });
  };

  handleBicChange = (val: any) => {
    this.setState({ bic: val });
  };

  handleAccountOwnerChange = (val: any) => {
    this.setState({ accountOwner: val });
  };

  handleRegisterClick = () => {
    if (this.state.privacyAccepted) {
      this.setState({ requestLoading: true, requestErrMsg: null });
      const clubNews: Array<string> = [];
      if (this.state.informationPaper)
        clubNews.push("in Papierform / Vereinszeitung");
      if (this.state.informationElectric)
        clubNews.push("elektronisch / Newsletter");

      const departmentArr: Array<string> = this.state.departmentArr
        .filter((department: any) => department.value)
        .map((department: any) => department.id);
      fetchRetry(
        "sendMemAppFNew",
        {
          clubId: this.state.clubId,
          targetUserId: this.context.user ? this.context.selectedUserId : null,
          data: {
            gender: this.state.gender,
            name: {
              fName: this.state.fName,
              lName: this.state.lName,
            },
            address: {
              street: this.state.street,
              houseNumber: this.state.houseNumber,
              zipcode: this.state.zipcode,
              city: this.state.city,
            },
            phonenumber: this.state.phoneNumber,
            email: this.state.email,
            c_3: this.state.other,
            c_2: this.state.mobileNumber,
            c_4: this.state.isStudent ? "Ja" : "Nein",
            birthDate: dateToStr(inputStrToDate(this.state.birthDate)),
            joinDate: dateToStr(inputStrToDate(this.state.joinDate)),
            branch: departmentArr,
            clubnews: clubNews,
            c_adults: this.state.adults,
            c_children: this.state.children,
            sepa: {
              iban: this.state.iban,
              creditInstitution: this.state.creditInstitution,
              bic: this.state.bic,
              accountOwner: this.state.accountOwner,
            },
          },
        },
        1,
        5
      )
        .then(this.handleUsersToRegisterSuccess)
        .catch(this.handleError);
    } else {
      this.setState({ highlight: true });
    }
  };

  handleUsersToRegisterSuccess = ({ data }: any) => {
    this.setState({ requestLoading: false });
    if (data.success) {
      this.context.createInfo("Anfrage erfolgreich gesendet.", "success", 4);
      this.context.requestClub(this.state.clubId);
      if (this.state.nextRedirect) {
        window.location.href = this.state.nextRedirect;
      } else {
        this.setState({ redirect: `/club/${this.state.clubId}` });
      }
    } else {
      this.setState({ requestErrMsg: data.errorMsgDe });
      this.context.createInfo(data.errorMsgDe, "info", 4);
      if (data.id) {
        const elemId = data.id;
        var element = document.getElementById(elemId);
        element?.scrollIntoView();
      }
    }
  };

  changeSelectedDepartment = (index: number) => {
    const departmentArr = this.state.departmentArr;
    departmentArr[index].value = !departmentArr[index].value;
    this.setState({ departmentArr });
  };

  getQueryData = (joinBranch: boolean) => {
    const queryData: any = {
      redirect: `https://${this.context.club.nameIdentifier}.getbookable.de/club/${this.state.clubId}/Aufnahmeantrag?regReq=true`,
    };

    if (joinBranch) {
      queryData["joinBranch"] = true;
      queryData[
        "redirect"
      ] = `https://${this.context.club.nameIdentifier}.getbookable.de/club/${this.state.clubId}`;
    }

    if (this.state.nextRedirect) {
      queryData["nextRedirect"] = this.state.nextRedirect;
    }
    return queryData;
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.membershipAppSetLoaded || !this.state.userLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Werden Sie Mitglied beim SV Fellbach!" />
            {!this.context.user && this.state.registrationReq ? (
              <>
                <SubTitle title="Schritt 1 von 2 - Account erstellen" />
                <div className="mem-app-no-account-container">
                  <p>
                    Um einen Aufnahmeantrag zu stellen, wird zuerst ein Account
                    benötigt.
                  </p>
                  <Link
                    className="login-btn"
                    to={`/sign-up/${this.state.clubId}?${qs.stringify(
                      this.getQueryData(false)
                    )}`}
                  >
                    Account erstellen
                  </Link>
                </div>

                <span>
                  Du bist bereits ein Mitglied?{" "}
                  <Link
                    to={`/sign-up/${this.state.clubId}?${qs.stringify(
                      this.getQueryData(true)
                    )}`}
                  >
                    Jetzt Registrieren
                  </Link>
                </span>
              </>
            ) : (
              <>
                <UnderlinedTitle title="Persönliche Angaben" />
                <div
                  className="radio-btn-container"
                  onChange={this.onGenderChange}
                  id="gender"
                >
                  <input
                    type="radio"
                    id="input_gender_1"
                    name="gender"
                    value="weiblich"
                  />
                  <label>weiblich</label>
                  <input
                    type="radio"
                    id="input_gender_2"
                    name="gender"
                    value="männlich"
                  />
                  <label>männlich</label>
                  <input
                    type="radio"
                    id="input_gender_3"
                    name="gender"
                    value="divers"
                  />
                  <label>divers</label>
                </div>
                <SubTitle title="Name" id="name" />
                <div className="course-time-input-container">
                  <Input
                    value={this.state.fName}
                    onChange={this.handleFNameChange}
                    placeholder="Vorname"
                  />
                  <Input
                    value={this.state.lName}
                    onChange={this.handleLNameChange}
                    placeholder="Nachname"
                  />
                </div>
                <SubTitle title="Adresse" id="address" />
                <div className="course-time-input-container">
                  <Input
                    value={this.state.street}
                    onChange={this.handleStreetChange}
                    placeholder="Straße"
                    className="street-input"
                  />
                  <Input
                    value={this.state.houseNumber}
                    onChange={this.handleHouseNumberChange}
                    placeholder="Nr."
                    className="house-number-input"
                  />
                </div>
                <div className="profile-location-container">
                  <Input
                    value={this.state.zipcode}
                    onChange={this.handleZipcodeChange}
                    placeholder="PLZ"
                    className="zipcode-input"
                  />
                  <Input
                    value={this.state.city}
                    onChange={this.handleCityChange}
                    placeholder="Stadt"
                    className="city-input"
                  />
                </div>
                <SubTitle
                  title="Telefonnummer (mit Vorwahl)"
                  id="phonenumber"
                />
                <Input
                  value={this.state.phoneNumber}
                  onChange={this.handlePhoneNumberChange}
                  placeholder="Telefonnummer"
                />
                <SubTitle title="Mobilnummer" id="c_2" />
                <Input
                  value={this.state.mobileNumber}
                  onChange={this.handleMobileNumberChange}
                  placeholder="Mobilnummer"
                />
                <SubTitle title="E-mail" id="email" />
                <Input
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  placeholder="E-mail"
                />
                <SubTitle title="Geburtsdatum" id="birthDate" />
                <input
                  type="date"
                  className="input-container"
                  value={this.state.birthDate}
                  onChange={this.handleBirthDateChange}
                />
                <SubTitle
                  title="Eintritt in die Abteilung (ankreuzen)"
                  id="branch"
                />
                <div className="department-container">
                  {this.state.departmentArr.map(
                    (department: any, index: number) => (
                      <div className="department-inner-container">
                        <TextCheckbox
                          val={department.selected}
                          text={department.name}
                          onChange={() => {
                            this.changeSelectedDepartment(index);
                          }}
                        />
                      </div>
                    )
                  )}
                </div>
                <div className="medium-space"></div>
                Ich möchte Vereinsinformationen...
                <div className="checkbox-news-container" id="clubnews">
                  <div>
                    <input
                      type="checkbox"
                      checked={this.state.informationPaper}
                      onChange={() => {
                        this.setState({
                          informationPaper: !this.state.informationPaper,
                        });
                        if (!this.state.informationPaper) {
                          this.setState({ noInformation: false });
                        }
                      }}
                    />
                    <label>in Papierform / Vereinszeitung</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={this.state.informationElectric}
                      onChange={() => {
                        this.setState({
                          informationElectric: !this.state.informationElectric,
                        });
                        if (!this.state.informationElectric) {
                          this.setState({ noInformation: false });
                        }
                      }}
                    />
                    <label>elektronisch / Newsletter</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={this.state.noInformation}
                      onChange={() => {
                        this.setState({
                          noInformation: !this.state.noInformation,
                        });
                        if (!this.state.noInformation) {
                          this.setState({
                            informationElectric: false,
                            informationPaper: false,
                          });
                        }
                      }}
                    />
                    <label>gar nicht</label>
                  </div>
                </div>
                <SubTitle
                  title="Erklärt seinen/ihren Eintritt in den Sportverein Fellbach 1890 e.V. zum"
                  id="joinDate"
                />
                <input
                  type="date"
                  className="input-container"
                  value={this.state.joinDate}
                  onChange={this.handleJoinDateChange}
                />
                <div className="margin-bottom">
                  <TextCheckbox
                    val={this.state.isStudent}
                    text={
                      "Studierende, Azubi, FSJ, BFD, Rentner (Bescheinigung jährlich bis 1.12/01.05 in der Geschäftsstelle einreichen. Bei Neuaufnahme beilegen)"
                    }
                    onChange={() => {
                      this.setState({ isStudent: !this.state.isStudent });
                    }}
                  />
                </div>
                <UnderlinedTitle title="Weitere Familienmitglieder im SV Fellbach?" />
                <div className="margin-bottom">
                  <SubTitle title="Erwachsene" id="c_adults" />
                  <Input
                    value={this.state.adults}
                    onChange={this.handleAdultsChange}
                    placeholder="Erwachsene"
                  />
                  <SubTitle title="Kinder" id="c_children" />
                  <Input
                    value={this.state.children}
                    onChange={this.handleChildrenChange}
                    placeholder="Kinder"
                  />
                </div>
                <UnderlinedTitle title="Unsere Vereinsbeiträge" />
                <div className="margin-bottom">
                  <table className="membership-pricing-table">
                    <thead>
                      <tr>
                        <th colSpan={2}>(monatlich)</th>
                        <th colSpan={2}>(einmalig)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Erwachsene</td>
                        <td>€ 14,-</td>
                        <td>Aufnahmegebühr</td>
                        <td>€ 20,--</td>
                      </tr>
                      <tr>
                        <td>Ehepartner</td>
                        <td>€ 11,-</td>
                        <td>Aufnahmegebühr</td>
                        <td>€ 20,--</td>
                      </tr>
                      <tr>
                        <td>Kinder, Jugendliche (bis 18 J.)</td>
                        <td>€ 11,-</td>
                        <td>Aufnahmegebühr</td>
                        <td>€ 10,--</td>
                      </tr>
                      <tr>
                        <td>Azubis, Studenten</td>
                        <td>€ 11,-</td>
                        <td>Aufnahmegebühr</td>
                        <td>€ 10,--</td>
                      </tr>
                      <tr>
                        <td>Rentner, Versehrte</td>
                        <td>€ 11,-</td>
                        <td>Aufnahmegebühr</td>
                        <td>€ 10,--</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>Zusatzbeiträge in einigen Abteilungen.</p>
                </div>
                <UnderlinedTitle title="Unser Familienbeitrag" />
                <div className="margin-bottom">
                  <p>
                    Bei Mitgliedschaft von <b>zwei</b> Kindern einer Familie
                    sind weitere Kinder beitragsfrei. Bei Mitgliedschaft
                    mindestens eines Elternteils und <b>eines</b> Kindes (bis 18
                    Jahre) sind weitere Kinder beitragsfrei.
                  </p>
                </div>
                <UnderlinedTitle title="Beitragsabbuchung" />
                <div className="margin-bottom">
                  <p>
                    Die Mitgliedsbeiträge werden zweimal jährlich abgebucht.
                    (Bei Rechnungsempfängern zusätzliche Berechnung von € 5,--
                    pro Halbjahr).
                  </p>
                </div>
                <UnderlinedTitle title="SEPA-Lastschriftmandat" />
                <div className="margin-bottom">
                  <p>
                    Ich ermächtige den SV Fellbach, bis auf schriftlichen
                    Widerruf, den Mitgliedsbeitrag halbjährlich zum 01.01. und
                    01.07. zu Lasten meines Bankkontos einzuziehen. Zugleich
                    weise ich mein Kreditinstitut an, die vom SV Fellbach auf
                    mein Konto gezogenen Lastschriften einzulösen.
                  </p>
                  <p className="bold-text-spacing">
                    Gläubiger-ID: DE-70ZZZ00000043523
                  </p>
                  <p className="margin-bottom">
                    Ich kann innerhalb von acht Wochen, beginnend mit dem
                    Belastungsdatum, in berechtigten Fällen die Erstattung des
                    belasteten Betrages verlangen. Es gelten die mit meinem
                    Kreditinstitut vereinbarten Bedingungen. Bei Nicht-Erteilung
                    eines SEPA-Lastschriftmandats berechnen wir eine
                    Bearbeitungsgebühr von 5 €.
                  </p>
                  <SubTitle title="IBAN" id="sepa" />
                  <Input
                    value={this.state.iban}
                    onChange={this.handleIbanChange}
                    placeholder="IBAN"
                  />
                  <div className="profile-location-containe">
                    <div className="">
                      <SubTitle title="Kreditinstitut" />
                      <Input
                        value={this.state.creditInstitution}
                        onChange={this.handleCreditInstitutionChange}
                        placeholder="Kreditinstitut"
                      />
                    </div>
                    <div>
                      <SubTitle title="BIC" />
                      <Input
                        value={this.state.bic}
                        onChange={this.handleBicChange}
                        placeholder="BIC"
                      />
                    </div>
                  </div>
                  <SubTitle title="Kontoinhaber" />
                  <Input
                    value={this.state.accountOwner}
                    onChange={this.handleAccountOwnerChange}
                    placeholder="Kontoinhaber"
                  />
                </div>
                <UnderlinedTitle title="Kündigung der Mitgliedschaft" />
                <div className="margin-bottom">
                  <p className="margin-bottom">
                    Eine Kündigung ist zum 30. Juni und 31. Dezember eines jeden
                    Jahres möglich. Die Kündigung der Mitgliedschaft muss
                    mindestens einen Monat vorher schriftlich bei der
                    SVF-Geschäftsstelle erfolgen.
                  </p>
                </div>
                <UnderlinedTitle title="Sonstige Bemerkungen" />
                <Input
                  value={this.state.other}
                  onChange={this.handleOtherChange}
                  placeholder="Bemerkungen"
                />
                <div className="checkbox-container margin-bottom">
                  <div>
                    <TextCheckbox
                      highlight={this.state.highlight}
                      val={this.state.privacyAccepted}
                      onChange={() => {
                        this.setState({
                          privacyAccepted: !this.state.privacyAccepted,
                          highlight: this.state.privacyAccepted,
                        });
                      }}
                      text="Hiermit beantrage ich verbindlich die Aufnahme in den Sportverein Fellbach 1890 e. V. und anerkenne die Satzung und die Ordnung des Vereins sowie seiner sonstigen Bestimmungen. Die Beitragsordnung des Vereins habe ich zur Kenntnis genommen und erkenne diese an. Ich erkläre mich einverstanden, dass
                  im Rahmen der Vereinsverwaltung meine personenbezogenen Daten,
                  gemäß § 28 Bundesdatenschutzgesetz (BDSG), gespeichert und
                  verarbeitet werden."
                    />
                  </div>
                </div>
                <ErrorMessage message={this.state.requestErrMsg} />
                <SecButton
                  onClick={this.handleRegisterClick}
                  title="Verbindlich anmelden"
                  change={true}
                  loading={this.state.requestLoading}
                />
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
